<template>
  <div
    class="parent"
    :style="{ width: parentWidth, height: parentHeight }"
    v-show="isLoading"
  >
    <div class="loading">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lD",
  props: {
    parentWidth: {
      type: String,
      default: "100%",
    },
    parentHeight: {
      type: String,
      default: "50px",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  display: inline-block;
  text-align: center;
}

.spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #dcebf0;
  border-top-color: #f0d0e1;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
