// import { fakeData } from "./fakeData";
import axios from "@/common/http";
export default {
  state: () => ({
    menuList: [],
    allHomeContentList: [],
    currentContentList: [],
    currentId: "",
    sideBarClass: "",
    isPageHeaderShow: false,
    currentTitle: "",
    sideBarLoading: false,
  }),
  mutations: {
    setMenuList: (state, list) => {
      state.menuList = list;
    },
    setAllHomeContentList: (state, list) => {
      state.allHomeContentList = list;
    },
    setCurrentId: (state, value) => {
      state.currentId = value;
    },
    setCurrentList: (state) => {
      let allContentList = state.allHomeContentList;
      let id = state.currentId;
      const selectList = allContentList[id] || [];
      state.currentContentList = selectList;
    },
    setsideBarClass: (state, value) => {
      state.sideBarClass = value;
    },
    setIsPageHeaderShow: (state, value) => {
      state.isPageHeaderShow = value;
    },
    setCurrentTitle: (state) => {
      const menuList = state.menuList;
      let id = state.currentId;
      let currentMenu = {};
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].subclass) {
          const item = menuList[i].subclass.filter((it) => it.id === id);
          if (item[0]) {
            currentMenu = item[0];
          }
        } else if (menuList[i].id === id) {
          currentMenu = menuList[i];
        }
      }
      state.currentTitle = currentMenu.title || "AI Life Assitant";
    },
    setSideBarLoading: (state, value) => {
      state.sideBarLoading = value;
    },
  },
  actions: {
    async getHomePageAllData({ commit }) {
      commit("setSideBarLoading", true);
      const res = (await axios.get("/v1/banner/list")) || {
        data: {
          class: {},
          lists: [],
        },
      };
      commit("setMenuList", res.data.class);
      commit("setSideBarLoading", false);
      commit("setAllHomeContentList", res.data.list);
      // commit("setMenuList", fakeData.data.class);
      commit("setCurrentId", "1001");
      commit("setCurrentList");
      // return res;
    },
  },
  getters: {},
};
