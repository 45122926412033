import axios from "axios";
import Toast from "@/components/Toast/ToastIndex";

// Vue.use(Toast);
const domain = "https://qa.webapi.all-in-one.ai";
// const domain = "/api1";
axios.defaults.baseURL = domain;
axios.interceptors.request.use(
  (config) => {
    console.info(config);
    return config;
  },
  (error) => {
    console.info(error);
  }
);
axios.interceptors.response.use(
  (res) => {
    if (res.data) {
      if (res.data.status !== "200") {
        const message = res.data.msg || "Service error";
        Toast({
          type: "waring",
          message,
        });
      }
      return res.data;
    } else {
      Toast({
        type: "waring",
        message: "Service error",
      });
      return {
        status: res.status || "error",
        msg: "服务异常，请联系管理员",
      };
    }
  },
  (error) => {
    Toast({
      type: "waring",
      message: "Service error",
    });
    return Promise.reject(error);
  }
);

export default axios;
