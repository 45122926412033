<template>
  <div class="page_header header_wrap" @click="handleHeaderClick">
    <div class="logo-wrap">
      <div class="logo-icon"></div>
      ALL-IN-ONE
    </div>
    <div class="show" @click="handleHeaderClick">show</div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    return {};
  },
  methods: {
    handleHeaderClick() {
      this.$emit("handleHeaderClick");
    },
  },
};
</script>
<style lang="less" scoped>
.page_header {
  height: 50px;
  // background: rgba(44, 46, 47, 0.7);
  position: absolute;
  // position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}
.logo-wrap {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 0;
  .logo-icon {
    margin-right: 6px;
    width: 30px;
    height: 30px;
    background: url("../assets/images/ailogo.png");
    background-size: 100% 100%;
    font-weight: bold;
  }
  color: #d0cddc;
  font-weight: 900;
}
.show {
  line-height: 50px;
}
</style>
