<template>
  <div class="sidebar">
    <div class="logo-wrap">
      <div class="logo-icon" />
      ALL-IN-ONE
    </div>
    <div class="sidebar-scroll">
      <div class="sidebar-menu">
        <div v-if="!sideBarLoading">
          <div v-for="(menuItem, index) in menuBarList" :key="menuItem.id">
            <div class="first-menu-wrap" @click="handleOpenOrChange(index)">
              <p class="first-menu-title">{{ menuItem.title }}</p>
              <div
                class="content-arrow"
                v-if="menuItem.subclass && menuItem.subclass.length"
              >
                <img
                  :src="selectedFirstIndex === index ? arrawUp : arrawDown"
                />
              </div>
            </div>
            <div
              class="secound-menu-wrap"
              :class="currentId == secondMenu.id ? 'activeSecond' : ''"
              v-for="secondMenu in menuItem.subclass"
              :key="secondMenu.id"
              v-show="selectedFirstIndex === index"
              @click="handleChange(secondMenu.id)"
            >
              <p class="secound-title">{{ secondMenu.title }}</p>
            </div>
          </div>
        </div>
        <div v-else>
          <LoadingCom
            :parentWidth="'100%'"
            :parentHeight="'400px'"
            :isLoading="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SideBar",
  data() {
    return {
      selectedFirstIndex: null,
      arrawUp: require("@/assets/images/icons/arrow_up.png"),
      arrawDown: require("@/assets/images/icons/arrow_down.png"),
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      menuBarList: (state) => state.homepage.menuList,
      currentId: (state) => state.homepage.currentId,
      sideBarLoading: (state) => state.homepage.sideBarLoading,
    }),
  },
  methods: {
    handleOpenOrChange(index) {
      if (this.selectedFirstIndex === index) {
        this.selectedFirstIndex = null;
      } else {
        this.selectedFirstIndex = index;
      }
      const subcalss = this.menuBarList[index].subclass;
      // 只有一级菜单
      if (!subcalss) {
        const id = this.menuBarList[index].id;
        this.handleCommonChange(id);
      } else if (
        subcalss &&
        subcalss.length &&
        typeof this.selectedFirstIndex == "number"
      ) {
        //定位到开了的一级菜单下的子菜单第一个;
        const id = this.menuBarList[index].subclass[0].id;
        this.handleCommonChange(id);
      }
    },
    //显示器小于168 收缩
    handleCommonChange(id) {
      this.$store.commit("setCurrentId", id);
      this.$store.commit("setCurrentList");
      this.$store.commit("setCurrentTitle");
      const appEle = document.querySelector("#app");
      const pageWidth = appEle.clientWidth;
      if (pageWidth < 768) {
        this.$store.commit("setsideBarClass", "no-sidebar");
        this.$store.commit("setIsPageHeaderShow", true);
      }
    },
    handleChange(id) {
      this.handleCommonChange(id);
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar-scroll {
  overflow: scroll;
  height: 95vh;
  &::-webkit-scrollbar {
    display: none;
  }
}
.sidebar {
  position: fixed;
  min-height: 100vh;
  z-index: 10;
  background: #2c2e2f;
  width: max-content;
  color: #fff;
  padding: 0 14px;
  .sidebar-menu {
    width: 220px;
  }
}
.logo-wrap {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 0;
  .logo-icon {
    margin-right: 6px;
    width: 30px;
    height: 30px;
    background: url("../assets/images/ailogo.png");
    background-size: 100% 100%;
    font-weight: bold;
  }
}
.first-menu-wrap {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  .first-menu-title {
    overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 20px;
  }
  .content-arrow {
    width: 25px;
    img {
      width: 100%;
      display: block;
    }
  }
}
.secound-menu-wrap {
  font-size: 12px;
  margin-left: 14px;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 20px;
  text-overflow: ellipsis;
}
.activeSecond {
  background: #ccc;

  border-radius: 10px;
  color: purple;
}
.activeSecond > p {
  margin: 10px;
}
</style>
