import vue from "vue";
// 导入自定义到Toast组件
import CustToast from "./CustomToast.vue";
/**
 * 调用示例子
 *  this.$toast("我是一个Toast轻提示");
    this.$toast({ type: "right", message: "llll", duration: 2, });
    duration 传0  不消失，可手动清除
 * **/
// 生成一个扩展实例构造器
let ToastConstructor = vue.extend(CustToast);
let instance;
let seed = 1; // 计数
const Toast = (options = {}) => {
  //判断如果传入的是一个字符串，那么就使用message提示
  if (typeof options === "string") {
    options = {
      message: options,
    };
  }
  let id = `toast_${seed++}`;
  instance = new ToastConstructor({
    data: options,
  }); // 创建对象实例
  instance.id = id;
  // 手动挂载一个实例并插入到 body 中
  instance.$mount();
  document.body.appendChild(instance.$el);
  instance.showToast = true;
  return instance;
};
export default Toast;
