<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import "@/assets/css/reset.css";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {},
  methods: {},
  created() {},
};
</script>
<style lang="less">
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
