<template>
  <div class="home" :class="sideBarClass">
    <SideBar></SideBar>
    <div class="main">
      <PageHeader
        v-if="isPageHeaderShow"
        @handleHeaderClick="handleHeaderClick"
      ></PageHeader>
      <!-- <div class="content">content</div> -->
      <!-- <HomeContent /> -->
      <div class="content">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
      <span class="mask" @click="handleMaskClick" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from "@/components/Sidebar.vue";
import PageHeader from "@/components/PageHeader.vue";
import { mapState } from "vuex";
export default {
  name: "HomeView",
  data() {
    return {
      currentPath: "homepage",
    };
  },
  components: {
    SideBar,
    PageHeader,
    // HomeContent,
  },
  methods: {
    // 绑定事件
    // 参数 target: 目标元素
    // 参数 type: 事件类型
    // 参数 callback: 回调函数
    // 参数 useCapture: 是否捕获
    addEvent(target, type, callback, useCapture) {
      if (target.addEventListener) {
        target.addEventListener(type, callback, useCapture);
      } else if (target.attachEvent) {
        target.attachEvent("on" + type, callback);
      } else {
        target["on" + type] = callback;
      }
    },

    resizeCallback() {
      console.log("111");
      const appEle = document.querySelector("#app");
      const pageWidth = appEle.clientWidth;
      if (pageWidth < 1024 && pageWidth >= 768) {
        this.$store.commit("setsideBarClass", "mini-sidebar");
        this.$store.commit("setIsPageHeaderShow", false);
      } else if (pageWidth < 768) {
        this.$store.commit("setsideBarClass", "no-sidebar");
        this.$store.commit("setIsPageHeaderShow", true);
      } else {
        this.$store.commit("setsideBarClass", "");
      }
    },
    handleHeaderClick() {
      const appEle = document.querySelector("#app");
      const pageWidth = appEle.clientWidth;
      if (pageWidth < 768) {
        this.$store.commit("setsideBarClass", "float-sidebar");
        this.$store.commit("setIsPageHeaderShow", false);
      }
    },
    handleMaskClick() {},
  },
  computed: {
    ...mapState({
      sideBarClass: (state) => state.homepage.sideBarClass,
      isPageHeaderShow: (state) => state.homepage.isPageHeaderShow,
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.currentPath = to.name;
      },
    },
  },
  created() {
    this.resizeCallback();
    this.addEvent(document, "DOMContentLoaded", this.resizeCallback);
    this.addEvent(window, "resize", this.resizeCallback);
    this.$store.dispatch("getHomePageAllData");
  },
};
</script>
<style lang="less">
.home {
  height: 100%;
  &.no-sidebar {
    .sidebar {
      display: none;
    }
    .main {
      padding-left: 0;
      // padding-top: 50px;
      // .content {
      //   height: calc(100vh-50px);
      // }
    }
  }
  &.mini-sidebar {
    .main {
      padding-left: 112px;
    }
    .sidebar-menu {
      width: 60px;

      .first-menu-wrap {
      }
      // transform: scale(0.8);
    }
  }
  &.float-sidebar {
    .main {
      padding-left: 348px;
      position: relative;
      filter: blur(5px);
      .mask {
        width: 100%;
        height: 100vh;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 11;
        background-color: rgba(240, 238, 238, 0.1);
      }
    }
    .sidebar-menu {
      width: 320px;
    }
  }
  .main {
    padding-left: 248px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    // padding-top: 50px;
    min-width: 320px;
  }
}
</style>
