import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "",
        redirect: "homepage",
      },
      {
        path: "homepage",
        name: "homepage",
        component: () =>
          import(/* webpackChunkName: "homepage" */ "../views/HomeContent.vue"),
      },
      {
        path: "detailedpage",
        name: "detailedpage",
        component: () =>
          import(
            /* webpackChunkName: "detailedpage" */ "../views/DetailedPage.vue"
          ),
      },
    ],
  },
  // {
  //   path: "*",
  //   name: "home",
  //   component: HomeView,
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
