<template>
  <div class="CustToast" :class="type" v-if="showToast">
    <span class="icon">
      <img :src="iconSrc" />
    </span>
    <span class="toast-message">{{ message }}</span>
    <p @click="handleClose" class="toast-close">
      <img :src="closIcon" alt="info" class="close-icon" />
    </p>
  </div>
</template>

<script>
export default {
  /**
   * params: showToast Boolean 是否激活toast 默认 false
   * params: type String       toast提示类型 共normal success，fail，warning 四个选项 默认normal
   * params: message String    toast消息
   * params: duration Number      toast显示时间 默认 2ms
   * */
  name: "CustToast",
  data() {
    return {
      showToast: true,
      type: "right",
      message: "消息提示",
      duration: 2,
      closed: false, // 用来判断消息框是否关闭
      timer: null,
      closIcon: require("@/assets/images/icons/icon_toast_close.png"),
    };
  },
  watch: {
    closed(val) {
      if (val) {
        this.showToast = false;
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.destroyElement();
      }
    },
  },
  computed: {
    iconSrc() {
      let tipType = ["right", "info", "waring"];
      if (tipType.includes(this.type)) {
        return require(`@/assets/images/icons/icon_${this.type}.png`);
      } else {
        throw "Toast type数据只允许为 right, waring,info 三种其中的一种，默认为right";
      }
    },
  },
  methods: {
    handleClose() {
      this.closed = true;
    },
    //完全销毁实例
    destroyElement() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    },
    //计时器，根据 duration 去修改组件的开关状态
    startTimer() {
      console.log(this.duration, "this.duration");
      if (this.duration) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.closed = true;
            clearTimeout(this.timer);
          }
        }, this.duration * 1000);
      }
    },
  },
  mounted() {
    this.startTimer();
  },
};
</script>

<style scoped lang="less">
.CustToast {
  position: fixed;
  left: 83%;
  top: 10%;
  background: rgb(243, 243, 245);
  padding: 10px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  animation: show-toast 0.2s;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 364px;
  height: 72px;
  z-index: 900;
  border-radius: 10px;
  font-size: 0;
}
.toast-close {
  position: absolute;
  right: 20px;
}
@keyframes show-toast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.toast-message {
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.3rem;
  font-size: 14px;
  font-family: PingFangTC-Medium, PingFangTC;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  opacity: 0.6;
}
.right {
  //   color: #67c23a;
  //   background: rgb(225, 243, 216);
}

// .warning {
//   color: #e6a23c;
//   background: rgb(250, 236, 216);
// }

.error {
  //   color: #f56c6c;
  //   background: rgb(253, 226, 226);
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
